import { useMemo } from 'react';

export default function useNavigationMetadata(pageContext, siteUrl) {
    const { pageNumber, pageCount, slug } = pageContext;

    return useMemo(
        () => ({
            prev:
                pageNumber > 1
                    ? `${siteUrl}${getPageUrl(slug, pageNumber - 1)}`
                    : undefined,
            next:
                pageNumber < pageCount
                    ? `${siteUrl}${getPageUrl(slug, pageNumber + 1)}`
                    : undefined,
        }),
        [siteUrl, slug, pageNumber, pageCount]
    );
}

function getPageUrl(slug, page) {
    return `/${slug}/${page > 1 ? page : ''}`;
}
