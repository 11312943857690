import React from 'react';
import { graphql } from 'gatsby';

import {
    list,
    title,
    blog,
    blogWrapper,
    paginationBox,
    tagList,
    inputSearch,
} from './blog.module.scss';
import FaqEmotics from '../assets/images/svg/faq-emotics.svg';
import { config } from '../config';
import listItemToJSONLD from '../utills/list-item-to-json-ld';
import useCategories from '../hooks/use-categories';
import useNavigationMetadata from '../hooks/use-navigation-metadata';
import getNodes from '../utills/get-nodes';

import Main from '../layouts/main';
import Emotic from '../components/atoms/emotic';
import Title from '../components/atoms/title';
import SubTitle from '../components/atoms/subtitle';
import Pagination from '../components/molecules/pagination';
import BlogListing from '../components/organisms/blog-listing';
import SEO from '../components/seo';
import CategoriesBar from '../components/organisms/categories-bar';
import useBlog from '../hooks/use-blog';
import TagList from '../components/molecules/tag-list';
import InputSearch from '../components/atoms/form-poc/input-search';

const { apiStatusMap } = config;

export default function Blog({ data, pageContext }) {
    const { allPost, site, postCategory, allPostCategory } = data;
    const { pageNumber, pageCount, allPostCount, categoryId } = pageContext;
    const posts = getNodes(allPost);
    const postsCategories = getNodes(allPostCategory);
    const siteUrl = site.siteMetadata.url;

    const { pageTitle, pageDescription, categories } = useCategories(
        postCategory,
        postsCategories,
        {
            title: 'Blog',
            descriptionField: 'description',
            mergeCategoriesOptions: {
                totalItemCount: allPostCount,
                itemCountName: 'postCount',
                firstCategoryName: 'Wszystkie posty',
            },
        }
    );
    const isCategoryPage = categoryId !== null;

    const navigation = useNavigationMetadata(pageContext, siteUrl);

    const {
        filters,
        hasFilters,
        tags,
        posts: dynamicPosts,
        pagination,
        status,
        getNextPage,
        handlePageChange,
        handleFilterChange,
    } = useBlog();

    return (
        <>
            <Main className={blogWrapper}>
                <SEO
                    title={pageTitle}
                    description={pageDescription}
                    structuredData={{
                        '@type': 'ItemList',
                        name: `Blog`,
                        itemListElement: listItemToJSONLD(posts, `${siteUrl}`),
                    }}
                    navigation={navigation}
                />
                <section className={title}>
                    <Emotic emotic={FaqEmotics} />
                    <Title Tag="h1">Blog</Title>
                    <SubTitle>
                        Dajemy porady, przepisy, przedstawiamy i rozwiązujemy problemy!
                    </SubTitle>
                </section>
                <section className={blog}>
                    {categories.length > 1 && (
                        <CategoriesBar categories={categories} rootPath="blog" />
                    )}
                    {!isCategoryPage && (
                        <>
                            <InputSearch
                                className={inputSearch}
                                wait={1000}
                                initValue={filters.search}
                                reinitialize={true}
                                onChange={(value) => handleFilterChange('search', value)}
                            />
                            <TagList
                                className={tagList}
                                active={!hasFilters || status !== apiStatusMap.loading}
                                selectable={true}
                                tags={tags}
                                onClick={(tag) =>
                                    handleFilterChange('tags', tag.seeAll ? '' : tag.name)
                                }
                            />
                        </>
                    )}
                    <div className={list}>
                        <BlogListing
                            isDynamic={hasFilters}
                            status={status}
                            getNextPage={getNextPage}
                            posts={hasFilters ? dynamicPosts : posts}
                            hasMore={pagination.currentPage < pagination.pageCount}
                        />
                    </div>
                    {!hasFilters && (
                        <Pagination
                            className={paginationBox}
                            page={pageNumber}
                            pageCount={pageCount}
                            onChange={handlePageChange}
                            prefix="blog"
                            type="url"
                        />
                    )}
                </section>
            </Main>
        </>
    );
}

export const query = graphql`
    query($postIds: [Int!], $categoryId: Int) {
        allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        postCategory(categoryId: { eq: $categoryId }) {
            ...postCategoryFields
        }
        allPostCategory(sort: { fields: name, order: ASC }, filter: { postCount: { gt: 0 } }) {
            edges {
                node {
                    ...postCategoryFields
                }
            }
        }
        site {
            ...siteFields
        }
    }
`;
