import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from '@reach/router';

import { config } from '../config';
import { getPostsFetch } from '../communication/blog';

const { apiStatusMap } = config;

const seeAllTag = {
    tagId: 0,
    seeAll: true,
    name: 'Wszystkie',
    selected: true,
};

const initialFilters = {
    tags: [],
    search: '',
};

const initialBlogData = {
    items: [],
    pagination: {
        pageCount: 1,
        currentPage: 0,
        perPage: 9,
    },
};

const useBlog = () => {
    const { search, pathname } = useLocation();
    const { allTag } = useStaticQuery(query);
    const tags = [seeAllTag, ...allTag.edges.map(({ node }) => node)];
    const [filters, setFilters] = useState(getFiltersFromUrl(search, initialFilters));
    const [blogData, setBlogData] = useState(initialBlogData);
    const [status, setStatus] = useState(apiStatusMap.loading);

    const handlePageChange = async (page) => {
        await navigate(
            `${!pathname.endsWith('/') ? pathname.slice(0, -1) : pathname}${page > 1 ? page : ''}`
        );
    };

    const handleFilterChange = async (field, value) => {
        let newFilters = {};
        if (Array.isArray(filters[field])) {
            let newFilter = [];
            if (value) {
                newFilter = filters[field].includes(value)
                    ? filters[field].filter((item) => item !== value)
                    : [...filters[field], value];
            }
            newFilters = {
                ...filters,
                [field]: newFilter,
            };
        } else {
            newFilters = {
                ...filters,
                [field]: value,
            };
        }

        const filtersWithValue = {};
        Object.keys(newFilters).forEach((key) => {
            if (newFilters[key].length) {
                filtersWithValue[key] = newFilters[key];
            }
        });

        if (Object.keys(filtersWithValue).length) {
            const newPathname = getPathname(pathname);
            await navigate(
                `${newPathname}?${queryString.stringify(filtersWithValue, {
                    arrayFormat: 'bracket-separator',
                    arrayFormatSeparator: ',',
                    skipEmptyString: true,
                })}`
            );
        } else {
            setBlogData(initialBlogData);
            setStatus(apiStatusMap.idle);
            await navigate(pathname);
        }
    };

    const getPosts = async (page, perPage, tagIds, search) => {
        setStatus(apiStatusMap.loading);
        try {
            const response = await getPostsFetch(page, perPage, tagIds, search);
            setBlogData({
                items: page > 1 ? [...blogData.items, ...response.data.items] : response.data.items,
                pagination: response.data.pagination,
            });
            setStatus(apiStatusMap.success);
        } catch {
            setStatus(apiStatusMap.error);
        }
    };

    useEffect(() => {
        if (search) {
            const newFilters = getFiltersFromUrl(search, initialFilters);
            setBlogData(initialBlogData);
            setFilters(newFilters);
            getPosts(
                1,
                blogData.pagination.perPage,
                getTagIdsFromFilters(tags, newFilters),
                newFilters.search
            ).then();
        } else {
            setFilters(initialFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return {
        tags: getTagsWithSelectedField(tags, filters),
        status,
        posts: blogData.items,
        pagination: blogData.pagination,
        filters,
        hasFilters: !!search,
        getNextPage: () =>
            getPosts(
                blogData.pagination.currentPage + 1,
                blogData.pagination.perPage,
                getTagIdsFromFilters(tags, filters),
                filters.search
            ),
        handlePageChange,
        handleFilterChange,
    };
};

function getPathname(oldPathname) {
    return `/${oldPathname
        .split('/')
        .filter((pathPart) => {
            return isNaN(Number(pathPart));
        })
        .join('/')}/`;
}

function getTagsWithSelectedField(tags, filters) {
    if (filters.tags.length > 0) {
        return tags.map((tag) => ({
            ...tag,
            selected: filters.tags.includes(tag.name),
        }));
    }
    return tags.map((tag) => ({ ...tag, selected: !!tag.seeAll }));
}

function getTagIdsFromFilters(tags, filters) {
    return filters.tags.map((tagName) => tags.find((tag) => tagName === tag.name).tagId);
}

function getFiltersFromUrl(search, filters) {
    return {
        ...filters,
        ...queryString.parse(search, {
            arrayFormat: 'bracket-separator',
            arrayFormatSeparator: ',',
        }),
    };
}

// type for tags related to posts equals 1
const query = graphql`
    query {
        allTag(filter: { type: { eq: 1 }, itemsCount: { gt: 0 } }) {
            edges {
                node {
                    id
                    tagId
                    name
                    color
                }
            }
        }
    }
`;

export default useBlog;
