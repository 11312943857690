import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { list, empty } from './blog-listing.module.scss';
import { config } from '../../config';

import PostPreview from '../molecules/post-preview';
import Loader from '../atoms/loader';
import ErrorForm from '../atoms/form/error-form';

const { apiStatusMap } = config;

const BlogListing = ({
    isDynamic = false,
    posts = [],
    status = apiStatusMap.loading,
    hasMore,
    getNextPage,
}) => {
    return isDynamic ? (
        <>
            {status === apiStatusMap.loading && !posts.length && <Loader />}
            <InfiniteScroll
                scrollThreshold={0.4}
                dataLength={posts.length}
                next={getNextPage}
                hasMore={hasMore}
                loader={status === apiStatusMap.loading ? <Loader /> : null}
            >
                <div className={list}>
                    {posts.map((post) => (
                        <PostPreview key={`infinite-scroll-${post.articleId}`} post={post} />
                    ))}
                </div>
                {apiStatusMap.success === status && !posts.length && (
                    <p className={empty}>Nie znaleziono artykułów o podanych parametrach.</p>
                )}
                {apiStatusMap.error === status && (
                    <ErrorForm>Wystąpił błąd w trakcie ładowania artykułów...</ErrorForm>
                )}
            </InfiniteScroll>
        </>
    ) : (
        <div className={list}>
            {posts.map((post) => (
                <PostPreview key={`static-${post.articleId}`} post={post} />
            ))}
        </div>
    );
};

export default BlogListing;
